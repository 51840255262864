@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

/* syncfusion */
@import url("https://cdn.syncfusion.com/ej2/material.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  
  /* font-family: 'Rubik', sans-serif; */
  font-family: 'Segoe UI', sans-serif;
  /* background-color: white !important; */
}

ol{
  list-style-type:decimal	;
}

.ant-layout{
  background: rgb(255, 255, 255);
  /* background: rgb(255, 251, 251); */
}

.ant-input{
  font-family: 'Segoe UI', sans-serif;
}

.ant-input-number .ant-input-number-handler-wrap{
  display: none !important;
}

.ant-input-number .ant-input-number-input{
  text-align: right;
}


.ant-btn-primary{
  background-color: #1890FF;
  border: none;
  box-shadow: none;
}

.ant-menu-light{
  background: transparent;
}

.ant-form-item .ant-form-item-label>label {
  height:100px !important;
}


.mynavbar{
  backdrop-filter: blur(5px);
}

